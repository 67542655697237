<template>
    <div class="CutMoney">
        <ReportView
            ref="refsReportView"
            :options="options"
            @changeDeep = changeDeep
        >
            <template #sort>
                <el-dropdown style="margin-top: 7px;margin-right: 10px">
                    <el-button class="el-dropdown-link">{{lang['el-button.sort']}}</el-button>
                    <template #dropdown>
                        <el-dropdown-menu v-if="version === 1">
                            <el-dropdown-item @click="SortData('use')">{{lang['TableColumn.label.use']}}</el-dropdown-item>
                            <el-dropdown-item @click="SortData('have')">{{ lang['TableColumn.label.money'] }}</el-dropdown-item>
                            <el-dropdown-item @click="SortData('all')">{{lang['TableColumn.label.all']}}</el-dropdown-item>
                        </el-dropdown-menu>
                        <el-dropdown-menu v-else>
<!--                            月费版次数总=代理商内账号切割总数[不含下级]-->
                            <el-dropdown-item @click="SortData('use')">{{lang['TableColumn.label.all']}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
        </ReportView>
    </div>

</template>

<script setup>
import ReportView from "../../components/Report/ReportView";
import {onMounted, ref} from "vue";
import axiosConfig from "../../api/axiosConfig";
import cookies from "../../utils/cookies";
import common from "../../utils/common";
const version = Number(cookies.getCookie('version'));
import langLib from '@/lang/index';
import {SortReportArr} from "../../utils/ReportFormsCommon";
const lang = langLib.getLang('ReportForms','AgentRec')

const options = ref({
    TableColumn:[
        {label:lang['TableColumn.label.id'],prop:'id',width:90},
        {label:lang['TableColumn.label.leader'],prop:'leader',},
        {label:lang['TableColumn.label.agent'],prop:'username',},
        {label:lang['TableColumn.label.use'],prop:'use',},
        {label:lang['TableColumn.label.money'],prop:'have',},
        {label:lang['TableColumn.label.all'],prop:'all',},
    ],
})
const refsReportView = ref();
const IsDeep = ref(false);
const ReportArrAll = ref([]);
const GetReport = () => {
    axiosConfig.post('ReportForms.User/GetAgentRec',{deep:IsDeep.value ? 1 : 0}).then(res=>{
        if (!common.checkRes(res,true)){return}
        refsReportView.value.SetTableDataArr(res.data.data);
        ReportArrAll.value = res.data.data;
    })
}
onMounted(()=>{
    GetReport();
    if(version === 2){
        options.value.TableColumn = [
            {label:lang['TableColumn.label.id'],prop:'id',width:90},
            {label:lang['TableColumn.label.leader'],prop:'leader',},
            {label:lang['TableColumn.label.agent'],prop:'username',},
            {label:lang['TableColumn.label.all'],prop:'use',},//月费版次数总=代理商内账号切割总数[不含下级]

        ];
    }
})

const changeDeep = (bool) => {
    IsDeep.value = bool;
    GetReport();
}

const SortData = (field) => {
    refsReportView.value.SetTableDataArr(SortReportArr(ReportArrAll,field))
}
</script>

<style scoped>

</style>